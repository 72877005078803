import { HoneydewTimers } from '@common/games/Honeydew/types';
import { JalapenoTimers } from '@common/games/Jalapeno/types';
import {
  TrioBotDifficulty,
  TrioBotDifficultySetting,
  TrioPoints,
  TrioTimers,
} from '@common/games/Trio/types';
import { GameName } from '@common/types/games';

type ConfigValueSchema =
  | string
  | number
  | boolean
  | ReadonlyArray<ConfigValueSchema>
  | { [key: string]: ConfigValueSchema };

type ConfigKeySchema = `${string}_${string}`;
type ConfigSchema = Readonly<Record<ConfigKeySchema, ConfigValueSchema>>;

export enum GameStatus {
  Hidden = 0,
  Visible = 1,
  ComingSoon = 2,
  New = 3,
  Beta = 4,
  NonDiscordOnly = 5,
  Legacy = 6,
}

export const defaults = {
  /**
   * Game statuses
   */
  root_gameStatuses: {
    Jalapeno: GameStatus.Visible,
    Trio: GameStatus.Legacy,
    KitchenSink: GameStatus.Hidden,
    Avocado: GameStatus.Visible,
    Durian: GameStatus.Legacy,
    Honeydew: GameStatus.Legacy,
    Guava: GameStatus.Legacy,
    Farkleberry: GameStatus.Hidden,
    McMind: GameStatus.Hidden,
    Clementine: GameStatus.Hidden,
  } as Record<string, GameStatus>,

  /**
   * Game rankings
   */
  root_gameNamesSorted_Mobile: [
    'Jalapeno',
    'Avocado',
    'Durian',
    'Trio',
    'Honeydew',
    'Guava',
    'Farkleberry',
    'McMind',
    'Clementine',
    'KitchenSink',
  ] satisfies GameName[],

  root_gameNamesSorted_Desktop: [
    'Durian',
    'Avocado',
    'Jalapeno',
    'Trio',
    'Guava',
    'Honeydew',
    'Farkleberry',
    'McMind',
    'Clementine',
    'KitchenSink',
  ] satisfies GameName[],

  /**
   * Root
   */
  root_JotaiDevTools: false,
  root_ShowStartTimer: true,
  root_bypassMinPlayersCheck: false,
  root_keepToastsOnScreen: false,
  root_animationCompletionTimeout: 10,
  root_animationCompletionCheckInterval: 0.1,
  root_skipFramerMotionAnimations: false,
  root_skipCoverSheet: false,
  root_disablePublicRooms: false,
  root_defaultDesktopGame: 'Jalapeno' satisfies GameName,

  discord_skipGuildMemberFetch: false,

  /**
   * Network
   */
  net_heartbeatIntervalSeconds: 4,
  net_secondsAfterLastSeenToDisconnectWhileInLobby: 5,
  net_secondsAfterLastSeenToDisconnectWhileInGame: 8,
  net_secondsAfterHeartbeatExpirationToRemovePlayer: 45,
  net_secondsAfterNonHeartbeatDisconnectionToRemovePlayer: 45,
  net_allowPlayerRemovalDuringGameDueToSocketDisconnection: false,

  /**
   * Chat
   */
  chat_cooldownMessageLimit: 4,
  chat_cooldownTimeWindow: 11,
  chat_characterLimit: 50,

  /**
   * Emote
   */
  emote_emoteCooldownSeconds: 1.5,

  /**
   * Avocado
   */
  avocado_pointsForCorrectGuess: 50,
  avocado_pointsForJudgesChoice: 100,
  avocado_pointsToWin: 500,

  avocado_judgeChoosePromptTime: 15,
  avocado_submissionTime: 45,
  avocado_judgeReadEachAnswerTimer: 5,
  avocado_submissionTimeOnePlayer: 5,
  avocado_choiceTime: 20,
  avocado_animationSpeedMultiplier: 1,
  avocado_outcomeLingerTime: 10,
  avocado_scoreboardTime: 10,

  avocado_submissionMinCharsToShowLimit: 50,
  avocado_submissionCharacterLimit: 120,
  avocado_allowEarlyPromptSubmission: false,

  jalapeno_pointsToWin: 7,
  jalapeno_numWhiteCardsPerPlayer: 5,
  jalapeno_timers: {
    AgeGate: -1,
    MainMenu: -1,
    PlayBlackCard: 15,
    ReadBlackCard: 0.1,
    Play: 40,
    Reveal: 0,
    JudgePickCard: 20,
    Award: 20,
    GameOver: 30,
  } satisfies JalapenoTimers,
  jalapeno_defaultCardDurationSeconds: 1.2,
  jalapeno_showAwardHostControlsDelaySeconds: 4,
  jalapeno_defaultVoiceId: 'N2lVS1w4EtoT3dr4eOWO', // 'Callum' voice ID
  jalapeno_defaultPublicRoomContentPackId: 140000001, // Standard SFW Content Pack
  /**
   * Durian
   */
  durian_configureTime: 20,
  durian_chooseCategoryTime: 20,
  durian_hintTime: 60,
  durian_timerDisplayThreshForHinting: 45,
  durian_delayBetweenRevealForHints: 2,
  durian_delayBetweenAvatarAndHintForHints: 0.5,
  durian_voteTime: 45,
  durian_timerDisplayThreshForVoting: 30,
  durian_spyRedemptionTime: 21,
  durian_delayBetweenRevealForRedemption: 0.1,
  durian_delayBetweenAvatarAndHintForRedemption: 0,
  durian_endScreenTime: 20,

  /**
   * Farkleberry
   */
  farkleberry_roundTime: 45,
  farkleberry_totalRounds: 3,

  /**
   * Honeydew
   */
  honeydew_scoringPoints: 100,
  honeydew_bombingPoints: 50,
  honeydew_numImages: 12,
  honeydew_bombLifeTurns: 5,
  honeydew_numStartingBombsPerPlayer: 2,

  honeydew_timers: {
    CustomizeGame: { seconds: 15, options: { delay: 10 } },
    GiveHint: { seconds: 20, options: { delay: 1 } },
    Guess: { seconds: 10, options: { delay: 1 } },
    RevealGuesses: { seconds: 0.2 },
    RevealBombs: { seconds: 0.5 },
    ResolveBomb: { seconds: 1 },
    ResolveScoring: { seconds: 1.2 },
    TransitionScoreboard: { seconds: 5 },
    GameOverScoreboard: { seconds: 60 },
    BombAnnouncement: { seconds: 1.2 },
  } satisfies HoneydewTimers,

  guava_chooseGameModeTime: 15,
  guava_chooseContentPackTime: 15,
  guava_numStartingLifelines: 3,
  guava_pickingWordTime: 20,
  guava_guessingTime: 20,
  guava_revealTime: 10,
  guava_scoreboardTime: 15,
  guava_guessingTimeOnePlayer: 5,
  guava_isGuessingTimed: true,
  guava_delayBetweenWordPickerScreenAndGameBoard: 3,
  guava_competitionPoints: 50,
  guava_pointsToWin: 500,
  guava_lifeLineRewardThresholds: [
    3, 6, 10, 15, 20, 30, 40, 50, 60, 70, 80, 100,
  ],
  /**
   * McMind
   */
  mcmind_lives: 3,
  mcmind_isPressAndHoldEnabled: true,

  /**
   * Trio
   */
  trio_boardWidth: 3,
  trio_boardHeight: 3,
  trio_handSize: 2,
  trio_totalTurns: 20,
  trio_wildsInDeck: 0,
  trio_missedTurnsUntilInactive: 2,

  trio_bot: {
    difficulty: {
      easy: {
        beatsTile: 0,
        tiesTile: 0,

        threeAvailableSlots: 0,
        sixAvailableSlots: 0,
        nineAvailableSlots: 0,

        chaseThreeOfAKind: 1,
        chaseOneOfEach: 1,
        chaseSingle: 1,

        blockThreeOfAKind: 0,
        blockOneOfEach: 0,
      },
      hard: {
        beatsTile: 2,
        tiesTile: 1,

        threeAvailableSlots: 0,
        sixAvailableSlots: 0,
        nineAvailableSlots: 1,

        chaseThreeOfAKind: 4,
        chaseOneOfEach: 3,
        chaseSingle: 2,

        blockThreeOfAKind: 2,
        blockOneOfEach: 1,
      },
    } satisfies Record<TrioBotDifficulty, TrioBotDifficultySetting>,
  },

  trio_points: {
    goal: 6,
    Straight: 3,
    Flush: 2,
    Basic: 1,
  } satisfies TrioPoints,

  trio_timers: {
    MainMenu: 30,
    PlaceTiles: 12,
    RevealPlacements: 0.1,
    ResolveCombatZone: 0.3,
    Battle: 6,
    BattleReveal: 0.5,
    BattleReward: 0.1,
    Scoring: 0.1,
    GameOverTransition: 2,
    GameOver: 0,
  } satisfies TrioTimers,

  miniAvocado_enabled: true,

  /**
   * Testing
   */
  testing_muteMusic: false,
  testing_muteSoundEffects: false,
  testing_alwaysUseStaticAvatars: false,
  /**
   * ScoreScreen
   */
  ScoreScreen_showBottomRowDelay: 1.5,
} as const satisfies ConfigSchema;

/**
 * This config type will always reflect DEFAULT config values.
 * Runtime values MAY differ, if they have been overriden due to:
 * 1) A /config POST request, which overrides the config for a specific room
 * 2) A local-overrides.json file, which overrides the config for all rooms (when running locally)
 */
export type Config = typeof defaults;
