import { GameName, GameNameIncludingLobby } from './games';

export type RoomInfo = {
  roomId: string;
  selectedGame: GameName | null;
  currentGame: GameNameIncludingLobby | null;
  numPlayers: number;
};

export type CreateRoomPortalRequest = {
  publicRoomId: string;
};

export type RoomPortalResponse = {
  publicRoomId: string | null;
};

const validPublicRoomIdsPrefixes = [
  'PUBLIC1',
  'PUBLIC2',
  'PUBLIC3',
  'PUBLIC4',
  'PUBLIC5',
];

export const getValidPublicRoomIds = (deploymentVersion: string) => {
  return validPublicRoomIdsPrefixes.map(
    (prefix) => `${prefix}-${deploymentVersion.toUpperCase()}`
  );
};

const gameNamesToPublicRoomIds: GameName[] = [
  'Jalapeno',
  'Avocado',
  'Durian',
  'Trio',
  'Guava',
];

export function getGameForPublicRoomId(
  publicRoomId: string,
  deploymentVersion: string
): GameName | null {
  const allPublicRoomIds = getValidPublicRoomIds(deploymentVersion);
  const index = allPublicRoomIds.indexOf(publicRoomId);
  if (index === -1) return null;
  const game = gameNamesToPublicRoomIds[index];
  if (!game) return null;
  return game;
}

export function getPublicRoomIdForGame(
  game: GameName,
  deploymentVersion: string
): string | null {
  const index = gameNamesToPublicRoomIds.indexOf(game);
  if (index === -1) return null;
  const allPublicRoomIds = getValidPublicRoomIds(deploymentVersion);
  const publicRoomId = allPublicRoomIds[index];
  if (!publicRoomId) return null;
  return publicRoomId;
}
