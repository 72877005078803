import { PlayerId } from './player';

export enum CurrencyGrantType {
  NewPlayerGrant = 'NewPlayerGrant',
  AdminGrant = 'AdminGrant',
  MiniAvocadoAnswerPrize = 'MiniAvocadoAnswerPrize',
  DefaultCosmeticReplacement = 'DefaultCosmeticReplacement',
}

export enum CurrencyTransactionPurpose {
  CosmeticPurchase = 'CosmeticPurchase',
  GrantClaim = 'GrantClaim',
  ChallengeClaim = 'ChallengeClaim',
  PollCompletion = 'PollCompletion',
  MiniAvocadoAnswer = 'MiniAvocadoAnswer',
  KingPayout = 'KingPayout',
  Transfer = 'Transfer',
  JalapenoRoundWin = 'JalapenoRoundWin',
  JalapenoGameWin = 'JalapenoGameWin',
  AvocadoJudgesChoice = 'AvocadoJudgesChoice',
}

export const breadWidgetGrantTypes: CurrencyGrantType[] = [
  CurrencyGrantType.NewPlayerGrant,
  CurrencyGrantType.AdminGrant,
  CurrencyGrantType.DefaultCosmeticReplacement,
];

export enum CreditTransactionPurpose {
  InitialGrant = 'InitialGrant',
  AdminGrant = 'AdminGrant',
  CustomAvocadoContentPack = 'CustomAvocadoContentPack',
  Replenishment = 'Replenishment',
  JalapenoRoundVoiceEnabled = 'JalapenoRoundVoiceEnabled',
  CustomJalapenoContentPack = 'CustomJalapenoContentPack',
}

export type CurrencyTransactionEvent = {
  playerId: PlayerId;
  updatedBalance: number;
  amount: number;
  purpose: CurrencyTransactionPurpose;
  transactionId: number;
};

export const CreditPrices = {
  CustomAvocadoContentPack: 1,
  JalapenoRoundVoiceEnabled: 1,
  CustomJalapenoContentPack: 1,
} as const;

export const ReplenishToAmount = 5;
export const ReplenishmentPeriodDurationMinutes = 20 * 60;
