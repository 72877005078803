import Bread_DonePopping_01 from '@/assets/sfx/Bread_DonePopping_01.mp3';
import Bread_PoppingLoop_01 from '@/assets/sfx/Bread_PoppingLoop_01.mp3';
import Bread_PullLever_01 from '@/assets/sfx/Bread_PullLever_01.mp3';
import Bread_SparkleLoop_01 from '@/assets/sfx/Bread_SparkleLoop_01.mp3';
import Bread_YouveGotBread_01 from '@/assets/sfx/Bread_YouveGotBread_01.mp3';
import Button_Avatar_01 from '@/assets/sfx/Button_Avatar_01.mp3';
import Button_Avatar_ColorChange_01 from '@/assets/sfx/Button_Avatar_ColorChange_01.mp3';
import Button_Avatar_ColorChange_02 from '@/assets/sfx/Button_Avatar_ColorChange_02.mp3';
import Button_Avatar_Randomizer_01 from '@/assets/sfx/Button_Avatar_Randomizer_01.mp3';
import Button_Avatar_ThatsMe_01 from '@/assets/sfx/Button_Avatar_ThatsMe_01.mp3';
import Button_Backward_01 from '@/assets/sfx/Button_Backward_01.mp3';
import Button_Cancel_01 from '@/assets/sfx/Button_Cancel_01.mp3';
import Button_ChoiceLock_01 from '@/assets/sfx/Button_ChoiceLock_01.mp3';
import Button_Confirm_01 from '@/assets/sfx/Button_Confirm_01.mp3';
import Button_Forward_01 from '@/assets/sfx/Button_Forward_01.mp3';
import Button_Hamburger_01 from '@/assets/sfx/Button_Hamburger_01.mp3';
import Button_Main_01 from '@/assets/sfx/Button_Main_01.mp3';
import Button_Main_02 from '@/assets/sfx/Button_Main_02.mp3';
import Button_MenuSelect_01 from '@/assets/sfx/Button_MenuSelect_01.mp3';
import Button_Modal_Close_01 from '@/assets/sfx/Button_Modal_Close_01.mp3';
import Button_Modal_Open_01 from '@/assets/sfx/Button_Modal_Open_01.mp3';
import Button_PhotoLabel_01 from '@/assets/sfx/Button_PhotoLabel_01.mp3';
import Button_SpendDonut_01 from '@/assets/sfx/Button_SpendDonut_01.mp3';
import Card_Palmed_01 from '@/assets/sfx/Card_Palmed_01.mp3';
import Card_Revealed_01 from '@/assets/sfx/Card_Revealed_01.mp3';
import Cards_Appear_01 from '@/assets/sfx/Cards_Appear_01.mp3';
import CountdownTimer_01 from '@/assets/sfx/CountdownTimer_01.mp3';
import Elevator_Ding_01 from '@/assets/sfx/Elevator_Ding_01.mp3';
import Emote_SFX_Happy_02 from '@/assets/sfx/Emote_SFX_Happy_02.mp3';
import Emote_SFX_Heart_02 from '@/assets/sfx/Emote_SFX_Heart_02.mp3';
import Emote_SFX_Love_02 from '@/assets/sfx/Emote_SFX_Love_02.mp3';
import Emote_SFX_Mad_02 from '@/assets/sfx/Emote_SFX_Mad_02.mp3';
import Emote_SFX_Sad_02 from '@/assets/sfx/Emote_SFX_Sad_02.mp3';
import Event_GameStart_01 from '@/assets/sfx/Event_GameStart_01.mp3';
import Game_Starts_Generic_01 from '@/assets/sfx/Game_Starts_Generic_01.mp3';
import Hourglass_Ends_01 from '@/assets/sfx/Hourglass_Ends_01.mp3';
import Hourglass_Starts_01 from '@/assets/sfx/Hourglass_Starts_01.mp3';
import Hourglass_Tick_01 from '@/assets/sfx/Hourglass_Tick_01.mp3';
import Hourglass_Tick_02 from '@/assets/sfx/Hourglass_Tick_02.mp3';
import Incorrect_results from '@/assets/sfx/Incorrect_results.mp3';
import Keyboard_Enter_01 from '@/assets/sfx/Keyboard_Enter_01.mp3';
import Keyboard_TypeTap_01 from '@/assets/sfx/Keyboard_TypeTap_01.mp3';
import Keyboard_TypeTap_02 from '@/assets/sfx/Keyboard_TypeTap_02.mp3';
import Keyboard_TypeTap_03 from '@/assets/sfx/Keyboard_TypeTap_03.mp3';
import Keyboard_TypeTap_04 from '@/assets/sfx/Keyboard_TypeTap_04.mp3';
import MenuSelect_button from '@/assets/sfx/MenuSelect_button.mp3';
import Page_Change_01 from '@/assets/sfx/Page_Change_01.mp3';
import Player_Appears_01 from '@/assets/sfx/Player_Appears_01.mp3';
import Player_Appears_2 from '@/assets/sfx/Player_Appears_2.mp3';
import Powerup_01 from '@/assets/sfx/Powerup_01.mp3';
import Prompt_Guess_01 from '@/assets/sfx/Prompt_Guess_01.mp3';
import Prompt_TextAnswer_01 from '@/assets/sfx/Prompt_TextAnswer_01.mp3';
import Results_Correct_01 from '@/assets/sfx/Results_Correct_01.mp3';
import Results_Incorrect_01 from '@/assets/sfx/Results_Incorrect_01.mp3';
import Results_Incorrect_02 from '@/assets/sfx/Results_Incorrect_02.mp3';
import Results_Incorrect_03 from '@/assets/sfx/Results_Incorrect_03.mp3';
import Results_Incorrect_04 from '@/assets/sfx/Results_Incorrect_04.mp3';
import Results_Incorrect_05 from '@/assets/sfx/Results_Incorrect_05.mp3';
import Results_Incorrect_06 from '@/assets/sfx/Results_Incorrect_06.mp3';
import Results_Incorrect_07 from '@/assets/sfx/Results_Incorrect_07.mp3';
import Results_Incorrect_08 from '@/assets/sfx/Results_Incorrect_08.mp3';
import Results_Incorrect_09 from '@/assets/sfx/Results_Incorrect_09.mp3';
import Score_Breakdown_01 from '@/assets/sfx/Score_Breakdown_01.mp3';
import Score_PlusOne_01 from '@/assets/sfx/Score_PlusOne_01.mp3';
import Score_PlusTwo_01 from '@/assets/sfx/Score_PlusTwo_01.mp3';
import Score_Promotion_01 from '@/assets/sfx/Score_Promotion_01.mp3';
import Search_results from '@/assets/sfx/Search_results.mp3';
import Swipe_AvatarSelect_Left_01 from '@/assets/sfx/Swipe_AvatarSelect_Left_01.mp3';
import Swipe_AvatarSelect_Right_01 from '@/assets/sfx/Swipe_AvatarSelect_Right_01.mp3';
import Tiles_Appear_01 from '@/assets/sfx/Tiles_Appear_01.mp3';
// This is a list of all the sound effects that we have in the game.
// When new sound assets are added to the project, they should be added here.
// This allows the playSoundEffect function to be able to play any sound effect by name.

const soundEffectsUrls = {
  Bread_DonePopping_01,
  Bread_PoppingLoop_01,
  Bread_PullLever_01,
  Bread_SparkleLoop_01,
  Bread_YouveGotBread_01,
  Button_Avatar_01,
  Button_Avatar_ColorChange_01,
  Button_Avatar_ColorChange_02,
  Button_Avatar_Randomizer_01,
  Button_Avatar_ThatsMe_01,
  Button_Backward_01,
  Button_Cancel_01,
  Button_ChoiceLock_01,
  Button_Confirm_01,
  Button_Forward_01,
  Button_Hamburger_01,
  Button_Main_01,
  Button_Main_02,
  Button_MenuSelect_01,
  Button_Modal_Close_01,
  Button_Modal_Open_01,
  Button_PhotoLabel_01,
  Button_SpendDonut_01,
  Card_Palmed_01,
  Card_Revealed_01,
  Cards_Appear_01,
  CountdownTimer_01,
  Elevator_Ding_01,
  Emote_SFX_Happy_02,
  Emote_SFX_Heart_02,
  Emote_SFX_Love_02,
  Emote_SFX_Mad_02,
  Emote_SFX_Sad_02,
  Event_GameStart_01,
  Game_Starts_Generic_01,
  Hourglass_Ends_01,
  Hourglass_Starts_01,
  Hourglass_Tick_01,
  Hourglass_Tick_02,
  Incorrect_results,
  Keyboard_Enter_01,
  Keyboard_TypeTap_01,
  Keyboard_TypeTap_02,
  Keyboard_TypeTap_03,
  Keyboard_TypeTap_04,
  MenuSelect_button,
  Page_Change_01,
  Player_Appears_01,
  Player_Appears_2,
  Powerup_01,
  Prompt_Guess_01,
  Prompt_TextAnswer_01,
  Results_Correct_01,
  Results_Incorrect_01,
  Results_Incorrect_02,
  Results_Incorrect_03,
  Results_Incorrect_04,
  Results_Incorrect_05,
  Results_Incorrect_06,
  Results_Incorrect_07,
  Results_Incorrect_08,
  Results_Incorrect_09,
  Score_Breakdown_01,
  Score_PlusOne_01,
  Score_PlusTwo_01,
  Score_Promotion_01,
  Search_results,
  Swipe_AvatarSelect_Left_01,
  Swipe_AvatarSelect_Right_01,
  Tiles_Appear_01,
};

export type SoundEffectsName = keyof typeof soundEffectsUrls;

export default soundEffectsUrls;
