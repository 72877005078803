import { GameMetaData } from '../types';
import ThumbnailA from './assets/Thumbnail_Jalapeno.png';
import Slide1 from './assets/howto/Slide1.png';
import Slide2 from './assets/howto/Slide2.png';
import Slide3 from './assets/howto/Slide3.png';
import Slide4 from './assets/howto/Slide4.png';

export default {
  name: 'Crimes Against Huge Manatees',
  primaryAccentColor: 'black',
  secondaryAccentColor: 'white',
  thumbnailImage: ThumbnailA,
  taglines: ['Fill-in-the-blank', 'Hilarious'],
  description: '',
  elevatorPitch: 'Play the funniest card to win!',
  minPlayers: 1,
  quickBits: [
    {
      emoji: '⏱️',
      text: '5-10 mins',
    },
    {
      emoji: '👥',
      text: '1+ players',
    },
  ],
  howToSlides: [
    {
      img: Slide1,
      imgAlt:
        'Each round, a JUDGE player submits a wacky PROMPT CARD that features a blank space.',
      isFullScreenImage: true,
    },
    {
      img: Slide2,
      imgAlt:
        'Other players each choose a FILLER CARD that best "fills in the blank"',
      isFullScreenImage: true,
    },
    {
      img: Slide3,
      imgAlt:
        'The JUDGE then chooses which FILLER CARD matches the PROMPT CARD. The player who submitted the best card gets A POINT!',
      isFullScreenImage: true,
    },
    {
      img: Slide4,
      imgAlt:
        'Need new cards? Use our AI-POWERED DECK GENERATOR to make your own!',
      isFullScreenImage: true,
    },
  ],
} satisfies GameMetaData;
